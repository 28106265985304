import React from 'react';

import { gear } from 'icons/SDS/allIcons';

import SDSButton, { ButtonType, ButtonShape, ButtonSize } from 'views/common/components/SDSButton/SDSButton';

import style from './DebugSDSButtons.scss';

function DebugSDSButtons() {
  return (
    <div className={style.wrapper}>
      {Object.keys(ButtonType).map(button => (
        <div key={button}>
          <h2>{button}</h2>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <SDSButton type={ButtonType[button]} inlineIcon={gear}>
            Button
          </SDSButton>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <SDSButton inlineIcon={gear} type={ButtonType[button]} shape={ButtonShape.CIRCLE} />
        </div>
      ))}
      {Object.keys(ButtonSize).map(button => (
        <div key={button}>
          <h2>{button}</h2>
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <SDSButton size={ButtonSize[button]} type={ButtonType.PRIMARY_ON_GREY} inlineIcon={gear}>
            Button
          </SDSButton>
          <SDSButton
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            size={ButtonSize[button]}
            inlineIcon={gear}
            type={ButtonType.PRIMARY_ON_GREY}
            shape={ButtonShape.CIRCLE}
          />
        </div>
      ))}
    </div>
  );
}

export default DebugSDSButtons;
