import React from 'react';
import { FormattedMessage } from 'react-intl';

const mandatoryIndicator = '*';

export const TRANSLATIONS = {
  publisher: (
    <FormattedMessage id="superadmin.publisher" defaultMessage="Main Settings" description="Publisher card title" />
  ),
  publisherNames: (
    <FormattedMessage
      id="superadmin.publisher-names"
      defaultMessage="Publisher Names"
      description="Publisher names card title"
    />
  ),
  websiteUrl: (
    <FormattedMessage id="superadmin.website-url" defaultMessage="Profile Url" description="Profile url card title" />
  ),
  storyDefaults: (
    <FormattedMessage
      id="superadmin.story-defaults"
      defaultMessage="Content Settings"
      description="Story Defaults card title"
    />
  ),
  webviewLimit: (
    <FormattedMessage
      id="superadmin.webview-limit"
      defaultMessage="Webviews Per Story"
      description="Maximum webpage attachments to have per story"
    />
  ),
  webviewLimitContent: (
    <FormattedMessage
      id="superadmin.webview-limit-content"
      defaultMessage="A story can have maximum of {field} webpage attachments"
      description="Webview limit content"
    />
  ),
  enabled: <FormattedMessage id="superadmin.enabled-setting" defaultMessage="Enabled" description="Enabled setting" />,
  disabled: (
    <FormattedMessage id="superadmin.disabled-setting" defaultMessage="Disabled" description="Disabled setting" />
  ),
  comScore: (
    <FormattedMessage
      id="superadmin.comscore-setting"
      defaultMessage="ComScore Integration"
      description="ComScore integration setting"
    />
  ),
  adSetting: (
    <FormattedMessage id="superadmin.adsetting" defaultMessage="Ad Settings" description="Ad skippable settings" />
  ),
  moderationLevel: (
    <FormattedMessage
      id="superadmin.moderationLevel"
      defaultMessage="Moderation Level"
      description="Moderation level settings"
    />
  ),
  ageGate: <FormattedMessage id="superadmin.age-gating" defaultMessage="Age Gating" description="Age Gating title" />,
  noAgeGate: (
    <FormattedMessage id="superadmin.no-age-gate" defaultMessage="All users" description="All users setting" />
  ),
  plus18Only: <FormattedMessage id="superadmin.18-only" defaultMessage="18+ only" description="18+ only setting" />,
  subscription: (
    <FormattedMessage
      id="superadmin.subscription"
      defaultMessage="Subscription Settings"
      description="Subscription title"
    />
  ),
  subscribable: (
    <FormattedMessage id="superadmin-able-to-add" defaultMessage="Able to add" description="Able to add setting" />
  ),
  notSubscribable: (
    <FormattedMessage
      id="superadmin.noadd"
      defaultMessage="Unable to add this profile"
      description="Not able to add setting"
    />
  ),
  publishing: (
    <FormattedMessage id="superadmin.publishing" defaultMessage="Publishing Status" description="Publishing title" />
  ),
  ableToPublish: (
    <FormattedMessage
      id="superadmin.able-to-publish"
      defaultMessage="Able to publish"
      description="Able to publish setting"
    />
  ),
  notAbleToPublish: (
    <FormattedMessage
      id="superadmin.not-able-to-publish"
      defaultMessage="Not able to publish"
      description="Not able to publish setting"
    />
  ),
  maxSnapDuration: (
    <FormattedMessage
      id="superadmin.maximum-snap-duration"
      defaultMessage="Maximum Snap Duration"
      description="Maximum snap duration title"
    />
  ),
  seconds10: <FormattedMessage id="superadmin.10-seconds" defaultMessage="10 seconds" description="10s setting" />,
  seconds20: <FormattedMessage id="superadmin.20-seconds" defaultMessage="20 seconds" description="20s setting" />,
  employee: (
    <FormattedMessage id="superadmin.employee" defaultMessage="Account Visibility" description="Employee only title" />
  ),
  employeeOnly: (
    <FormattedMessage
      id="superadmin.employee-only"
      defaultMessage="Employee Only"
      description="Employee only setting"
    />
  ),
  notEmployeeOnly: (
    <FormattedMessage id="superadmin.not-employee-only" defaultMessage="All Users" description="All users only" />
  ),
  advanceTopsnap: (
    <FormattedMessage
      id="superadmin.advance-topsnap"
      defaultMessage="Snap Behaviour"
      description="Auto-advance snap title"
    />
  ),
  advance: (
    <FormattedMessage id="superadmin.advance" defaultMessage="Auto-advance" description="Auto-advance setting" />
  ),
  dontAdvance: <FormattedMessage id="superadmin.do-not-advance" defaultMessage="Loop" description="Loop setting" />,
  testPublisher: (
    <FormattedMessage
      id="superadmin.test-publisher"
      defaultMessage="Publisher Status"
      description="Test publisher title"
    />
  ),
  test: <FormattedMessage id="superadmin.test" defaultMessage="Test Publisher" description="Test setting" />,
  normal: <FormattedMessage id="superadmin.normal" defaultMessage="Regular Publisher" description="Regular setting" />,
  shareOption: (
    <FormattedMessage
      id="superadmin.share-option"
      defaultMessage="Default Share Option"
      description="Default share option title"
    />
  ),
  noShare: <FormattedMessage id="superadmin.no-share" defaultMessage="No share" description="No share setting" />,
  shareLiveArchived: (
    <FormattedMessage id="superadmin.share-live-archived" defaultMessage="Share" description="Share setting" />
  ),
  publisherType: (
    <FormattedMessage
      id="superadmin.publisher-type"
      defaultMessage="Publishing Format"
      description="Publisher type setting"
    />
  ),
  urlSafeFormalName: (
    <FormattedMessage
      id="superadmin.url-safe-formal-name"
      defaultMessage="Snapcode URL Name (URL Safe)"
      description="Url safe formal name setting"
    />
  ),
  mutablePublisherName: (
    <FormattedMessage
      id="superadmin.mutable-publisher-name"
      defaultMessage="Story Studio Name"
      description="Mutable publisher name setting"
    />
  ),
  nicknames: (
    <FormattedMessage id="superadmin.nicknames" defaultMessage="Channel Search Terms" description="Nicknames setting" />
  ),
  emptySelection: (
    <FormattedMessage
      id="superadmin.nothing-selected"
      defaultMessage="None"
      description="Message when nothing is selected in the multi select dropdown"
    />
  ),
  adSettings: (
    <FormattedMessage
      id="superadmin.ad-settings"
      defaultMessage="Advertising In Content"
      description="Advertising setting title"
    />
  ),
  adsEnabled: (
    <FormattedMessage id="superadmin.ads-enabled" defaultMessage="Ads in stories" description="Ads enabled setting" />
  ),
  adsDisabled: (
    <FormattedMessage id="superadmin.ads-disabled" defaultMessage="No adverts" description="Ads disabled setting" />
  ),
  advancedAdsSettings: (
    <FormattedMessage
      id="superadmin.advanced-ad-settings"
      defaultMessage="Advanced Advertising"
      description="Advanced advertising setting"
    />
  ),
  advancedAdsEnabled: (
    <FormattedMessage
      id="superadmin.advanced-ads-enabled"
      defaultMessage="Advanced Ads"
      description="Advanced ads enabled setting"
    />
  ),
  advancedAdsDisabled: (
    <FormattedMessage
      id="superadmin.advanced-ads-disabled"
      defaultMessage="Simple Ads"
      description="Advanced ads disabled setting"
    />
  ),
  adsPreRollSettings: (
    <FormattedMessage
      id="superadmin.ads-pre-roll-settings"
      defaultMessage="Ads Pre-Roll"
      description="Ads Pre-Roll settings, when enabled may insert an ad before the start of the Story."
    />
  ),
  adTakeoverSetting: (
    <FormattedMessage
      id="superadmin.ad-takeover-setting"
      defaultMessage="Ad Takeover"
      description="Ad takeover setting"
    />
  ),
  defaultAdSlots: (
    <FormattedMessage
      id="superadmin.default-ad-slots"
      defaultMessage="Default Ad Slots"
      description="Default ad slots setting"
    />
  ),
  defaultAdSlotsMillis: (
    <FormattedMessage
      id="superadmin.takeover-ad-slots-seconds"
      defaultMessage="Takeover Ad Slots (Seconds)"
      description="Time-based Default Ad Slots for Takeover. User facing in seconds, but saved in millis"
    />
  ),
  noAds: <FormattedMessage id="superadmin.no-ads" defaultMessage="No ads" description="No ads setting" />,
  advancedAdsMinSlots: (
    <FormattedMessage
      id="superadmin.advanced-ads-min-slots"
      defaultMessage="Minimum # of ads in stories"
      description="Min ad slots setting"
    />
  ),
  // eslint-disable-next-line max-len
  advancedAdsMinSlotsContent: (
    <FormattedMessage
      id="superadmin.advanced-ads-min-slots-content"
      defaultMessage="Stories will have at least {field} adverts"
      description="Min ad slots content"
    />
  ),
  advancedAdsMaxSlots: (
    <FormattedMessage
      id="superadmin.advanced-ads-max-slots"
      defaultMessage="Maximum # of ads in stories"
      description="Max ad slots setting"
    />
  ),
  // eslint-disable-next-line max-len
  advancedAdsMaxSlotsContent: (
    <FormattedMessage
      id="superadmin.advanced-ads-max-slots-content"
      defaultMessage="Stories will have a maximum of {field} adverts"
      description="Max ad slots content"
    />
  ),
  advancedAdsMinSpacing: (
    <FormattedMessage
      id="superadmin.advanced-ads-min-spacing"
      defaultMessage="Ad Spacing"
      description="Ad spacing setting"
    />
  ),
  advancedAdsMinSpacingContent: (
    <FormattedMessage
      id="superadmin.advanced-ads-min-spacing-content"
      defaultMessage="There will be at least {field} Snaps between adverts"
      description="Ad spacing content"
    />
  ),
  advancedAdsLatestFirstSlot: (
    <FormattedMessage
      id="superadmin.advanced-ads-latest-first-slot"
      defaultMessage="Earliest Ad Slots"
      description="Earliest ad slot setting"
    />
  ),
  advancedAdsLatestFirstSlotContent: (
    <FormattedMessage
      id="superadmin.advanced-ads-latest-first-slot-content"
      defaultMessage="The first ad will not happen after Snap # {field}"
      description="Earliest ad slot content"
    />
  ),
  exclusiveShow: (
    <FormattedMessage
      id="superadmin.exclusive-show"
      defaultMessage="Exclusive Show"
      description="Exclusive show title"
    />
  ),
  exclusiveShowExclusive: (
    <FormattedMessage id="superadmin.exclusive" defaultMessage="Exclusive" description="Exclusive show setting" />
  ),
  exclusiveShowNonexclusive: (
    <FormattedMessage
      id="superadmin.nonexclusive"
      defaultMessage="Not Exclusive"
      description="Non exclusive show setting"
    />
  ),
  originalShow: (
    <FormattedMessage
      id="superadmin.original-show"
      defaultMessage="Snap Original Show"
      description="Snap Original show title"
    />
  ),
  originalShowOriginal: (
    <FormattedMessage id="superadmin.original" defaultMessage="Original" description="Snap original show setting" />
  ),
  originalShowNonoriginal: (
    <FormattedMessage
      id="superadmin.nonoriginal"
      defaultMessage="Not Original"
      description="Not Snap original show setting"
    />
  ),
  primaryLanguage: (
    <FormattedMessage
      id="superadmin.primary-language-settings"
      defaultMessage="Content Language"
      description="Primary language setting"
    />
  ),
  primaryLanguageMandatory: (
    <FormattedMessage
      id="superadmin.primary-language-settings-mandatory"
      defaultMessage="Content Language{mandatoryIndicator}"
      description="Primary language setting (mandatory)"
      values={{ mandatoryIndicator }}
    />
  ),
  skippable: (
    <FormattedMessage id="superadmin.ad-skippable" defaultMessage="Skippable" description="Skippable ad setting" />
  ),
  nonSkippable: (
    <FormattedMessage
      id="superadmin.ad-nonskippable"
      defaultMessage="Non Skippable"
      description="Non Skippable ad setting"
    />
  ),
  moderationLevelStandard: (
    <FormattedMessage
      id="superadmin.moderation-level-standard"
      defaultMessage="Standard"
      description="Standard Moderation Level"
    />
  ),
  moderationLevelNews: (
    <FormattedMessage id="superadmin.moderation-level-news" defaultMessage="News" description="News Moderation Level" />
  ),
  articleFooterIcon: (
    <FormattedMessage
      id="superadmin.article-footer-icon-settings"
      defaultMessage="Article Footer Icon"
      description="Article footer icon setting"
    />
  ),
  distribution: (
    <FormattedMessage id="superadmin.distribution" defaultMessage="Distribution" description="Distribution" />
  ),
  icons: <FormattedMessage id="superadmin.icon-settings" defaultMessage="Icons" description="Icons setting" />,
  language: <FormattedMessage id="superadmin.language" defaultMessage="Language List" description="Language setting" />,
  searchable: (
    <FormattedMessage
      id="superadmin.searchable"
      defaultMessage="Searchable"
      description="if a publisher is searchable in discover"
    />
  ),
  notSearchable: (
    <FormattedMessage
      id="superadmin.not-searchable"
      defaultMessage="Not searchable"
      description="is a publisher is not searchable in discover"
    />
  ),
  isSearchable: (
    <FormattedMessage
      id="superadmin.is-searchable"
      defaultMessage="Search"
      description="Title for modifying whether or not a publisher is searchable"
    />
  ),
  subscriberOnly: (
    <FormattedMessage id="superadmin-follower-only" defaultMessage="Followers Only" description="Follower only title" />
  ),
  isSubscriberOnly: (
    <FormattedMessage
      id="superadmin.is-follower-only"
      defaultMessage="Follower Only"
      description="Follower only setting"
    />
  ),
  notSubscriberOnly: (
    <FormattedMessage
      id="superadmin.not-follower-only"
      defaultMessage="Not follower only"
      description="Not follower only setting"
    />
  ),
  localContent: (
    <FormattedMessage
      id="superadmin.local-content"
      defaultMessage="Local Content"
      description="Local content setting"
    />
  ),
  showType: (
    <FormattedMessage
      id="publishersettings.show-type"
      defaultMessage="Local Content"
      description="Local content setting"
    />
  ),
  breakingNewsEnabled: (
    <FormattedMessage
      id="superadmin.breaking-news-enabled"
      defaultMessage="Breaking News"
      description="Breaking news title"
    />
  ),
  breakingNews: (
    <FormattedMessage
      id="superadmin.breaking-news"
      defaultMessage="Breaking News"
      description="Breaking news setting"
    />
  ),
  permitAutoPublishFromFeed: (
    <FormattedMessage
      id="superadmin.permit-auto-publishing"
      defaultMessage="Allow auto Submit"
      description="Permits auto publishing from feed seeting"
    />
  ),
  notBreakingNews: (
    <FormattedMessage
      id="superadmin.not-breaking-news"
      defaultMessage="Not Breaking News"
      description="Not breaking news setting"
    />
  ),
  archive: (
    <FormattedMessage
      id="superadmin.archive-publisher"
      defaultMessage="Archive Publisher"
      description="Archive publisher card name"
    />
  ),
  css: <FormattedMessage id="superadmin.css" defaultMessage="CSS" description="CSS card header" />,
  articleCSS: (
    <FormattedMessage id="superadmin.article-css" defaultMessage="Article CSS" description="Article CSS setting" />
  ),
  geofenceIds: (
    <FormattedMessage id="superadmin.geofence-ids" defaultMessage="Geofence Ids" description="Geofence Ids title" />
  ),
  countries: (
    <FormattedMessage id="superadmin.countries" defaultMessage="Countries" description="Countries settings card" />
  ),
  formalName: (
    <FormattedMessage id="superadmin.formal-name" defaultMessage="Formal Name" description="Formal name setting" />
  ),
  happeningNow: (
    <FormattedMessage
      id="superadmin.happeningNow"
      defaultMessage="Happening Now"
      description="Happening Now settings card"
    />
  ),
  madeForUs: (
    <FormattedMessage
      id="superadmin.made-for-us"
      defaultMessage="Made for Us"
      description="Label for made for us flag"
    />
  ),
  madeForUsToolTip: (
    <FormattedMessage
      id="superadmin.made-for-us-tool-tip"
      defaultMessage="Flag to indicate that a publisher can be shown in made for us feed"
      description="Tool Tip for made for us flag"
    />
  ),
  managerEmail: (
    <FormattedMessage
      id="superadmin.manager-email"
      defaultMessage="SPM Email Address"
      description="Spm email address setting"
    />
  ),
  managerEmailMandatory: (
    <FormattedMessage
      id="superadmin.partner-manager"
      defaultMessage="Partner Manager{mandatoryIndicator}"
      description="Partner Manager"
      values={{ mandatoryIndicator }}
    />
  ),
  paymentType: (
    <FormattedMessage id="superadmin.payment-type" defaultMessage="Payment Type" description="Payment type setting" />
  ),
  revShare: <FormattedMessage id="superadmin.rev-share" defaultMessage="Rev Share" description="Rev share option" />,
  timeUntilAutoPublishFeedStory: (
    <FormattedMessage
      id="superadmin.timeUntilAutoPublish"
      defaultMessage="Auto submit time delay"
      description="The feed story will be automatically submitted after this time passes"
    />
  ),
  timeUntilAutoPublishContent: (
    <FormattedMessage
      id="superadmin.timeUntilAutoPublishContent"
      defaultMessage="{field} minutes"
      description="A field to populate with how many minutes from now, the content will be published"
    />
  ),
  fixedPayments: (
    <FormattedMessage
      id="superadmin.fixed-payments"
      defaultMessage="Fixed Payments"
      description="Fixed payments option"
    />
  ),
  orgId: (
    <FormattedMessage
      id="superadmin.orgId"
      defaultMessage="Organisation Id"
      description="Organisation Id input title"
    />
  ),
  tier: <FormattedMessage id="superadmin.tier" defaultMessage="Tier" description="Tier option" />,
  publisherId: (
    <FormattedMessage
      id="superadmin.publisherId"
      defaultMessage="Publisher Id"
      description="Publisher Id input title"
    />
  ),
  businessProfileId: (
    <FormattedMessage
      id="superadmin.businessProfileId"
      defaultMessage="Business Profile Id"
      description="Business Profile Id input title"
    />
  ),
};

export const REQUIRED_PROPERTIES_TRANSLATIONS = {
  horizontalIconId: <FormattedMessage id="superadmin.required.horizontalIcon" defaultMessage="Wide Logo" />,
  description: <FormattedMessage id="superadmin.required.description" defaultMessage="Description" />,
  primaryColor: <FormattedMessage id="superadmin.required.primaryColor" defaultMessage="Primary Colour" />,
  squareHeroImageId: <FormattedMessage id="superadmin.required.squareHeroImage" defaultMessage="Profile Image" />,
  tags: <FormattedMessage id="superadmin.required.tags" defaultMessage="Tags" />,
  hostUsername: <FormattedMessage id="superadmin.required.hostUser" defaultMessage="Host User" />,
  homeCountry: <FormattedMessage id="superadmin.required.homeCountry" defaultMessage="Home Country" />,
  managerEmail: <FormattedMessage id="superadmin.required.managerEmail" defaultMessage="Partner Manager Email" />,
  managerName: <FormattedMessage id="superadmin.required.managerName" defaultMessage="Partner Manager Name" />,
  editorialApproval: (
    <FormattedMessage id="superadmin.required.editorialApproval" defaultMessage="Editorial Approval" />
  ),
  editorialRationale: (
    <FormattedMessage id="superadmin.required.editorialRationale" defaultMessage="Editorial Rationale" />
  ),
  contentLifespan: <FormattedMessage id="superadmin.required.contentLifespan" defaultMessage="Content Lifespan" />,
};
