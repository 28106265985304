import React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';

export type DeleteSnapComponentModalOptions = {
  onConfirm: () => void;
  body: ReactNode;
};

type Props = {
  modalId: number;
  hideModal: (a: number) => void;
  setModalConfigProperties: () => void;
  options: DeleteSnapComponentModalOptions;
};

export class DeleteSnapComponentModal extends React.Component<Props> {
  onHide = () => {
    const { modalId, hideModal } = this.props;
    hideModal(modalId);
  };

  onConfirm = () => {
    this.props.options.onConfirm();
    this.onHide();
  };

  render() {
    return (
      <SDSDialog
        visible
        isBodyCentered
        onOk={this.onConfirm}
        onCancel={this.onHide}
        okText={
          <FormattedMessage id="delete-component-delete-button" defaultMessage="Delete" description="Delete Button" />
        }
        cancelText={
          <FormattedMessage
            id="cancel-button-modal"
            defaultMessage="Cancel"
            description="Text indicating to user to cancel a prompt"
          />
        }
        data-test="modals.deleteSnap"
      >
        {this.props.options.body}
      </SDSDialog>
    );
  }
}

export default DeleteSnapComponentModal;
