import React, { useCallback } from 'react';

import { hideModal as hideModalAction } from 'state/modals/actions/modalsActions';

import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';

export type DialogModalOptions = {
  title?: JSX.Element;
  body: React.ReactNode | React.ReactNode[];
  onConfirm: () => void;
  onCancel?: () => void;
  disableCancel?: boolean;
  okText?: JSX.Element;
  cancelText?: JSX.Element;
  visible: boolean;
  isBodyCentered?: boolean;
  okButtonProps?: {
    disabled: boolean;
  };
};

type Props = {
  modalId?: string;
  hideModal?: typeof hideModalAction;
  'data-test'?: string;
  options: DialogModalOptions;
};

export function DialogModal(props: Props) {
  const { modalId, hideModal, options } = props;
  const onHide = useCallback(
    (confirmed: boolean) => {
      if (hideModal && modalId) {
        hideModal(modalId, { confirmed });
      }
    },
    [modalId, hideModal]
  );

  const onConfirm = useCallback(() => {
    options.onConfirm();
    onHide(true);
  }, [onHide, options]);

  const onCancel = useCallback(() => {
    if (options.onCancel) {
      options.onCancel();
    }
    onHide(false);
  }, [onHide, options]);

  return (
    <SDSDialog
      visible={props.options.visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={props.options.okText}
      cancelText={props.options.cancelText}
      title={props.options.title}
      data-test={`${props['data-test']}.sdsDialog`}
      isBodyCentered={props.options.isBodyCentered}
      okButtonProps={props.options.okButtonProps}
    >
      {props.options.body}
    </SDSDialog>
  );
}

export default DialogModal;
