import React from 'react';

import { isNewUserExperienceEnabled } from 'state/features/selectors/featuresSelectors';
import { openNewUserExperienceHelpModal, setSeenUserHelpModal } from 'state/user/actions/userActions';
import { getUserPreferences, isRecentlyCreatedUser } from 'state/user/selectors/userSelectors';
import { UserPreference } from 'state/user/userState';

import { intlConnect } from 'utils/connectUtils';

import { HelpModalTypes } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal';

import { State } from 'types/rootState';

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = {
  openNewUserExperienceHelpModal: (component: string, modalType: HelpModalTypes) => Promise<void>;
  setSeenUserHelpModal: () => Promise<void>;
  userPreferences: UserPreference;
} & StateProps;

const mapStateToProps = (state: State) => {
  const userPreferences = getUserPreferences(state);
  const isNewUserExperienceModalEnabled = isNewUserExperienceEnabled(state);
  const isNewlyCreatedUser = isRecentlyCreatedUser(state);
  return { userPreferences, isNewUserExperienceModalEnabled, isNewlyCreatedUser };
};

const mapDispatchToProps = {
  openNewUserExperienceHelpModal,
  setSeenUserHelpModal,
};

export class WithNewUserExperienceHelpModal extends React.Component<Props> {
  componentDidMount() {
    if (this.canShowHelpModal()) {
      this.props.openNewUserExperienceHelpModal('WithHelpModal', HelpModalTypes.NEW_PUBLISHER_MODAL).then(() => {
        this.props.setSeenUserHelpModal();
      });
    }
  }

  canShowHelpModal(): boolean {
    const { isNewlyCreatedUser, isNewUserExperienceModalEnabled } = this.props;
    const { hasSeenNewUserModalInSession, doNotShowNewUserModalAgain } = this.props.userPreferences;
    return (
      !hasSeenNewUserModalInSession &&
      !doNotShowNewUserModalAgain &&
      isNewUserExperienceModalEnabled &&
      isNewlyCreatedUser
    );
  }

  render() {
    return this.props.children;
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(WithNewUserExperienceHelpModal);
