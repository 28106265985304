import React from 'react';
import { FormattedMessage } from 'react-intl';

import { goToContact } from 'state/router/actions/routerActions';
import { clearScreenshot, takeScreenshot } from 'state/supportTickets/actions/supportTicketsActions';

import { intlConnect } from 'utils/connectUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import DialogModal, { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';

type DispatchProps = {
  goToContact: typeof goToContact;
  clearScreenshot: typeof clearScreenshot;
  takeScreenshot: typeof takeScreenshot;
};

type Props = DispatchProps;

const mapDispatchToProps = {
  goToContact,
  clearScreenshot,
  takeScreenshot,
};

export class TakeScreenshotModal extends React.Component<Props> {
  onDoTakeScreenshot = async () => {
    grapheneUtils.incrementCounter('TakeScreenshotModal', { choice: 'yes' });
    await this.props.takeScreenshot();
    this.props.goToContact();
  };

  onDoNotTakeScreenshot = () => {
    grapheneUtils.incrementCounter('TakeScreenshotModal', { choice: 'no' });
    this.props.clearScreenshot();
    this.props.goToContact();
  };

  render() {
    const options: DialogModalOptions = {
      onConfirm: this.onDoTakeScreenshot,
      onCancel: this.onDoNotTakeScreenshot,
      okText: (
        <FormattedMessage
          id="take-screenshot-prompt-yes"
          defaultMessage="Yes"
          description="Button caption to take a screenshot"
        />
      ),
      cancelText: (
        <FormattedMessage
          id="take-screenshot-prompt-no"
          defaultMessage="No"
          description="Button caption to not take a screenshot"
        />
      ),
      title: (
        <FormattedMessage
          id="take-screenshot-prompt-title"
          defaultMessage="Take a screenshot?"
          description="Headline at the prompt to take a screenshot"
        />
      ),
      body: (
        <FormattedMessage
          id="take-screenshot-prompt-message-line-1"
          defaultMessage="Would you like to take a screenshot to include in the contact message?"
          description="First line of message to prompt users to take a screenshot"
        />
      ),
      visible: true,
      isBodyCentered: true,
    };

    const newProps = { ...this.props, options };

    return <DialogModal {...newProps} />;
  }
}

export default intlConnect(null, mapDispatchToProps)(TakeScreenshotModal);
