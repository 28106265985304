import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isPublicStoryPostingEnabled } from 'state/features/selectors/featuresSelectors';

import { play, gridContent } from 'icons/SDS/allIcons';
import { incrementCounter } from 'utils/grapheneUtils';

import Icon from 'views/common/components/Icon/Icon';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';

import style from './NewContentModal.scss';

type NewContentOptions = {
  onNewStory: () => void;
  onNewSpotlight: () => void;
  onNewPublicStory: () => void;
};

type Props = {
  hideModal: (modalId: string) => void;
  modalId: string;
  options: NewContentOptions;
};

export default function NewContentModal({ hideModal, modalId, options }: Props) {
  const isPublicStoryEnabled = useSelector(isPublicStoryPostingEnabled);
  const onClose = useCallback(() => {
    hideModal(modalId);
  }, [hideModal, modalId]);

  const handleNewStory = useCallback(() => {
    options.onNewStory();
    incrementCounter('NewContentModal.NewStory.navigate');
    hideModal(modalId);
  }, [hideModal, modalId, options]);

  const handleNewSpotlight = useCallback(() => {
    options.onNewSpotlight();
    incrementCounter('NewContentModal.NewSpotlight.navigate');
    hideModal(modalId);
  }, [hideModal, modalId, options]);

  const handleNewPublicStory = useCallback(() => {
    options.onNewPublicStory();
    incrementCounter('NewContentModal.NewPublicStory.navigate');
    hideModal(modalId);
  }, [hideModal, modalId, options]);

  return (
    <SDSCustomModal
      onClose={onClose}
      footer={null}
      visible
      isBodyCentered
      data-test="modals.NewContentModal"
      width={isPublicStoryEnabled ? 1200 : 850} // TODO hack (mmeroi): Clean up asap
    >
      <div className={style.modalHeader}>
        <FormattedMessage
          id="new-content-header"
          description="header for new content modal"
          defaultMessage="Choose what to post"
        />
      </div>
      <div className={style.wrapper}>
        <div className={style.container} onClick={handleNewStory} data-test="modals.NewContentModal.NewStory.trigger">
          <Icon inlineIcon={gridContent} className={style.icon} />
          <div className={style.cellHeader}>
            <FormattedMessage
              id="post-to-stories"
              description="Text appearing in the new content modal that directs users to post to stories"
              defaultMessage="Post a Story"
            />
          </div>

          <FormattedMessage
            id="post-to-stories-subheading"
            description="Subheading appearing in the new content modal that directs users to post to stories"
            defaultMessage="Stories are created by select partners and are distributed in the Discover feed."
          />
        </div>
        <div
          className={style.container}
          onClick={handleNewSpotlight}
          data-test="modals.NewContentModal.NewSpotlight.trigger"
        >
          <Icon inlineIcon={play} className={style.icon} />
          <div className={style.cellHeader}>
            <FormattedMessage
              id="post-to-spotlight"
              description="Text appearing in the new content modal that directs users to post to spotlight"
              defaultMessage="Post to Spotlight"
            />
          </div>

          <FormattedMessage
            id="post-to-spotlight-subheading"
            description="Subheading appearing in the new content modal that directs users to post to spotlight"
            defaultMessage="Spotlight shines a light on the most entertaining Snaps."
          />
        </div>
        {isPublicStoryEnabled && (
          <div
            className={style.container}
            onClick={handleNewPublicStory}
            data-test="modals.NewContentModal.NewPublicStory.trigger"
          >
            <div className={style.betaWrapper}>
              <Icon inlineIcon={gridContent} className={style.icon} />
              <div className={style.betaLabel}>
                <FormattedMessage
                  id="beta-label"
                  description="Texts that signals Public Story is a beta feature"
                  defaultMessage="Beta"
                />
              </div>
            </div>

            <div className={style.cellHeader}>
              <FormattedMessage
                id="post-to-public-story"
                description="Text appearing in the new content modal that directs users to post to public story"
                defaultMessage="Post to Public Story"
              />
            </div>

            <FormattedMessage
              id="post-to-public-stories-subheading"
              description="Subheading appearing in the new content modal that directs users to post to public stories"
              defaultMessage="Stories disappear after 24 hours and are found in the Discover feed."
            />
          </div>
        )}
      </div>
    </SDSCustomModal>
  );
}
