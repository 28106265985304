import { keyBy, mapValues } from 'lodash';
import type { ReactElement, ReactNode } from 'react';

import { INFINITE_ADS_ID } from 'config/adsConfig';
import {
  AdSetting,
  AgeGate,
  AudioClassification,
  EditorialStatus,
  ModerationLevel,
  ShareOption,
  UploadPurpose,
} from 'config/constants';
import type { GeneratedPublisherTypeEnum } from 'utils/managePublisherUtils';
import { publisherTypeChoices } from 'utils/managePublisherUtils';

import { CountryList, LanguageList } from './countryLanguageLists';
import { TRANSLATIONS } from './superAdminTranslations';

import type { PickedCountryCodes } from 'types/countries';
import { Publisher, PublisherFeature, TierLevel } from 'types/publishers';

type TemporaryValues = {
  test: boolean;
  publisherType: GeneratedPublisherTypeEnum;
  horizontalIconBlob?: string;
  defaultFilledIconBlob?: string;
  distribution: PickedCountryCodes;
  contentLifespan: string;
};

type ButtonParams = {
  key: any;
  text: string | ReactNode;
};

export type TemporaryPublisher = Publisher & TemporaryValues;

export type PublisherKey = keyof TemporaryPublisher;

type DropdownParams = {
  value: any;
  label: string | ReactNode;
};

type PublisherKeyToButtons = {
  [key in PublisherKey]: {
    title: string | ReactNode;
    buttons: ButtonParams[];
  };
};

type PublisherFeaturesKeyToButtons = {
  [key in PublisherFeature]?: {
    title: string | ReactNode;
    buttons: ButtonParams[];
    toolTipText?: string | ReactNode;
  };
};

type PublisherKeyToDropdowns = {
  [key in PublisherKey]: {
    title: string | ReactNode;
    buttons: DropdownParams[] | undefined | null;
  };
};

type PublisherKeyToTextInput = {
  [key in PublisherKey]: {
    title: string | ReactNode;
    validationType?: string;
  };
};

type PublisherKeyToInlineNumberInput = {
  [key in PublisherKey]: {
    title: string | ReactNode;
    content: ReactElement<any>;
  };
};

type PublisherKeyToImageInput = {
  [key in PublisherKey]: {
    title: string | ReactNode;
  };
};

type PublisherKeyToCountryInput = {
  [key in PublisherKey]: {
    title: string | ReactNode;
  };
};

type PublisherKeyToMultiSelectInput = {
  [key in PublisherKey]: {
    title: string | ReactNode;
    buttons: {
      [x: string]: string;
    };
  };
};

export const CARD_HEADERS = {
  publisher: TRANSLATIONS.publisher,
  publisherNames: TRANSLATIONS.publisherNames,
  storyDefaults: TRANSLATIONS.storyDefaults,
  adSettings: TRANSLATIONS.adSettings,
  icons: TRANSLATIONS.icons,
  archive: TRANSLATIONS.archive,
  css: TRANSLATIONS.css,
  countries: TRANSLATIONS.countries,
  happeningNow: TRANSLATIONS.happeningNow,
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ adSetting: { title: Element; buttons: { ke... Remove this comment to see the full error message
export const BUTTONS_DEFINITION: PublisherKeyToButtons = {
  adSetting: {
    title: TRANSLATIONS.adSetting,
    buttons: [
      {
        key: AdSetting.SKIPPABLE,
        text: TRANSLATIONS.skippable,
      },
      {
        key: AdSetting.NON_SKIPPABLE,
        text: TRANSLATIONS.nonSkippable,
      },
    ],
  },
  moderationLevel: {
    title: TRANSLATIONS.moderationLevel,
    buttons: [
      {
        key: ModerationLevel.STANDARD,
        text: TRANSLATIONS.moderationLevelStandard,
      },
      {
        key: ModerationLevel.NEWS,
        text: TRANSLATIONS.moderationLevelNews,
      },
    ],
  },
  ageGate: {
    title: TRANSLATIONS.ageGate,
    buttons: [
      {
        key: AgeGate.UNRESTRICTED,
        text: TRANSLATIONS.noAgeGate,
      },
      {
        key: AgeGate.EXPLICIT,
        text: TRANSLATIONS.plus18Only,
      },
    ],
  },
  isComScoreIntegrationEnabled: {
    title: TRANSLATIONS.comScore,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.enabled,
      },
      {
        key: false,
        text: TRANSLATIONS.disabled,
      },
    ],
  },
  subscribable: {
    title: TRANSLATIONS.subscription,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.subscribable,
      },
      {
        key: false,
        text: TRANSLATIONS.notSubscribable,
      },
    ],
  },
  publishingEnabled: {
    title: TRANSLATIONS.publishing,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.ableToPublish,
      },
      {
        key: false,
        text: TRANSLATIONS.notAbleToPublish,
      },
    ],
  },
  isEmployeeOnly: {
    title: TRANSLATIONS.employee,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.employeeOnly,
      },
      {
        key: false,
        text: TRANSLATIONS.notEmployeeOnly,
      },
    ],
  },
  videoModeEnabled: {
    title: TRANSLATIONS.advanceTopsnap,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.advance,
      },
      {
        key: false,
        text: TRANSLATIONS.dontAdvance,
      },
    ],
  },
  topsnapLimit: {
    title: TRANSLATIONS.maxSnapDuration,
    buttons: [
      {
        key: 10,
        text: TRANSLATIONS.seconds10,
      },
      {
        key: 20,
        text: TRANSLATIONS.seconds20,
      },
    ],
  },
  test: {
    title: TRANSLATIONS.testPublisher,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.test,
      },
      {
        key: false,
        text: TRANSLATIONS.normal,
      },
    ],
  },
  defaultShareOption: {
    title: TRANSLATIONS.shareOption,
    buttons: [
      {
        key: ShareOption.NO_SHARE,
        text: TRANSLATIONS.noShare,
      },
      {
        key: ShareOption.SHARE_LIVE_ARCHIVED,
        text: TRANSLATIONS.shareLiveArchived,
      },
    ],
  },
  advertisingEnabled: {
    title: TRANSLATIONS.adSettings,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.adsEnabled,
      },
      {
        key: false,
        text: TRANSLATIONS.adsDisabled,
      },
    ],
  },
  advancedAdsEnabled: {
    title: TRANSLATIONS.advancedAdsSettings,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.advancedAdsEnabled,
      },
      {
        key: false,
        text: TRANSLATIONS.advancedAdsDisabled,
      },
    ],
  },
  adsPreRollEnabled: {
    title: TRANSLATIONS.adsPreRollSettings,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.enabled,
      },
      {
        key: false,
        text: TRANSLATIONS.disabled,
      },
    ],
  },
  dynamicAdsEnabled: {
    title: TRANSLATIONS.adTakeoverSetting, // To simplify the UI, we call dynamic ads "ad takeover".
    buttons: [
      {
        key: false, // Dynamic ads OFF is the same as ad takeover ON.
        text: TRANSLATIONS.enabled,
      },
      {
        key: true, // Dynamic ads ON is the same as ad takeover OFF.
        text: TRANSLATIONS.disabled,
      },
    ],
  },
  searchable: {
    title: TRANSLATIONS.isSearchable,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.searchable,
      },
      {
        key: false,
        text: TRANSLATIONS.notSearchable,
      },
    ],
  },
  rollingNewsEnabled: {
    title: TRANSLATIONS.breakingNewsEnabled,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.breakingNews,
      },
      {
        key: false,
        text: TRANSLATIONS.notBreakingNews,
      },
    ],
  },
  permitAutoPublishFromFeed: {
    title: TRANSLATIONS.permitAutoPublishFromFeed,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.enabled,
      },
      {
        key: false,
        text: TRANSLATIONS.disabled,
      },
    ],
  },
};

export const FEATURES_BUTTONS_DEFINITION: PublisherFeaturesKeyToButtons = {
  [PublisherFeature.EXCLUSIVE_SHOW]: {
    title: TRANSLATIONS.exclusiveShow,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.exclusiveShowExclusive,
      },
      {
        key: false,
        text: TRANSLATIONS.exclusiveShowNonexclusive,
      },
    ],
  },
  [PublisherFeature.ORIGINAL_SHOW]: {
    title: TRANSLATIONS.originalShow,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.originalShowOriginal,
      },
      {
        key: false,
        text: TRANSLATIONS.originalShowNonoriginal,
      },
    ],
  },
  [PublisherFeature.SUBSCRIBER_ONLY]: {
    title: TRANSLATIONS.subscriberOnly,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.isSubscriberOnly,
      },
      {
        key: false,
        text: TRANSLATIONS.notSubscriberOnly,
      },
    ],
  },
  [PublisherFeature.FIXED_PAYMENTS]: {
    title: TRANSLATIONS.paymentType,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.fixedPayments,
      },
      {
        key: false,
        text: TRANSLATIONS.revShare,
      },
    ],
  },
  [PublisherFeature.MADE_FOR_US]: {
    title: TRANSLATIONS.madeForUs,
    buttons: [
      {
        key: true,
        text: TRANSLATIONS.enabled,
      },
      {
        key: false,
        text: TRANSLATIONS.disabled,
      },
    ],
    toolTipText: TRANSLATIONS.madeForUsToolTip,
  },
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ publisherType: { title: Element; buttons: ... Remove this comment to see the full error message
export const DROPDOWNS_DEFINITION: PublisherKeyToDropdowns = {
  publisherType: {
    title: TRANSLATIONS.publisherType,
    buttons: publisherTypeChoices,
  },
  defaultAdSlots: {
    title: TRANSLATIONS.defaultAdSlots,
    buttons: [
      { value: '', label: TRANSLATIONS.noAds },
      { value: `2, 3, ${INFINITE_ADS_ID}`, label: '2,3,*' },
      { value: `2, 4, ${INFINITE_ADS_ID}`, label: '2,4,*' },
      { value: `3, ${INFINITE_ADS_ID}`, label: '3,*' },
      { value: `4, ${INFINITE_ADS_ID}`, label: '4,*' },
      { value: `5, ${INFINITE_ADS_ID}`, label: '5,*' },
      { value: '1, 1, 1, 1, 1, 1, 1', label: '1,1,1,1,1,1,1' },
      { value: '3, 2, 2, 3, 3, 3, 3', label: '3,2,2,3,3,3,3' },
      { value: '2, 3, 3, 3, 3, 3, 3', label: '2,3,3,3,3,3,3' },
      { value: '3, 3, 3, 3, 3, 3, 3', label: '3,3,3,3,3,3,3' },
      { value: '4, 4, 4', label: '4,4,4' },
      { value: '2, 3, 3, 3', label: '2,3,3,3' },
      { value: '4, 4, 4, 4', label: '4,4,4,4' },
      { value: `4, 4, 4, 4, 7, ${INFINITE_ADS_ID}`, label: '4,4,4,4,7,*' },
      { value: `4, 4, 4, 4, 10, ${INFINITE_ADS_ID}`, label: '4,4,4,4,10,*' },
      { value: `4, 5, 6, 7, 10, ${INFINITE_ADS_ID}`, label: '4,5,6,7,10,*' },
      { value: '5, 5, 5, 5, 5, 5, 5', label: '5,5,5,5,5,5,5' },
    ],
  },
  defaultAdSlotsMillis: {
    title: TRANSLATIONS.defaultAdSlotsMillis,
    buttons: [
      { value: '', label: TRANSLATIONS.noAds },
      { value: '5000, 25000, 30000, 30000', label: '5,30,60,90' },
      { value: '5000, 25000, 30000, -1', label: '5,30,60,*' },
      { value: '25000, 25000, 25000, 25000', label: '25,50,75,100' },
    ],
  },
  primaryLanguage: {
    title: TRANSLATIONS.primaryLanguageMandatory,
    buttons: LanguageList,
  },
  tier: {
    title: TRANSLATIONS.tier,
    buttons: [
      { value: TierLevel.PREMIUM, label: 'Premium Publisher' },
      { value: TierLevel.SHOW, label: 'Show Publisher' },
      { value: TierLevel.CURATION, label: 'Curation Publisher' },
      { value: TierLevel.HAPPENING_NOW, label: 'Happening Now' },
      { value: TierLevel.DYNAMIC_EDITIONS, label: 'Dynamic Editions' },
    ],
  },
  homeCountry: {
    title: 'Home country (internal use only)*',
    buttons: CountryList.map(({ value, label }) => ({ value: value.toUpperCase(), label })),
  },
  audioClassification: {
    title: 'Audio classification',
    buttons: [
      { value: AudioClassification.NORMAL_DIALOGUE, label: 'Normal Dialogue' },
      { value: AudioClassification.DIALOGUE_SUBTITLE_BURN, label: 'Dialogue Subtitle Burn' },
      { value: AudioClassification.DIALOGUE_IMPACT_BURN, label: 'Dialogue Impact Burn' },
      { value: AudioClassification.DIALOGUE_HEAVY, label: 'Dialogue Heavy' },
      { value: AudioClassification.NO_DIALOGUE, label: 'No Dialogue' },
    ],
  },
  editorialApproval: {
    title: 'Editorial Status',
    buttons: [
      { value: EditorialStatus.TO_BE_REVIEWED, label: 'Review Not Requested' },
      { value: EditorialStatus.REQUESTED, label: 'Review Requested' },
      { value: EditorialStatus.REJECTED, label: 'Rejected' },
      { value: EditorialStatus.APPROVED, label: 'Approved' },
    ],
  },
};

export const EDITORIAL_RATIONALE_DEFINITION = {
  [EditorialStatus.TO_BE_REVIEWED]: [],
  [EditorialStatus.REQUESTED]: [],
  [EditorialStatus.APPROVED]: [
    '1 - Priority Category',
    '2 - Engagement (one-off)',
    '3 - Experimental',
    '4 - Values-Driven',
    '5 - Partner Opportunity (Inbound Only)',
    '6 - Tentpole',
    '7 - Premium Narrative Channel',
    '8 - Internal Channel',
    '9 - Value driven - Hispanic (US only)',
    '10 - Value driven - Women',
    '11 - Value driven - Women UR',
    '12 - Value driven - LGBTQ+',
    '13 - RSAP UGC',
    '14 - Offboarded',
  ].map(x => ({ value: x, label: x })),
  [EditorialStatus.REJECTED]: [
    '1 - Likely to Violate Content Guidelines',
    '2 - Unlikely to Resonate',
    '3 - Oversaturated Category',
    '4 - Retention Concerns',
    '5 - Not Optimized for Snap',
    '6 - Not Local to Market',
    '7 - Incomplete Pitch',
    '8 - NON-RSAP UGC',
  ].map(x => ({ value: x, label: x })),
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ urlSafeFormalName: { title: Element; }; we... Remove this comment to see the full error message
export const TEXT_INPUT_DEFINITION: PublisherKeyToTextInput = {
  urlSafeFormalName: {
    title: TRANSLATIONS.urlSafeFormalName,
  },
  websiteUrl: {
    title: TRANSLATIONS.websiteUrl,
  },
  mutablePublisherName: {
    title: TRANSLATIONS.mutablePublisherName,
  },
  nicknames: {
    title: TRANSLATIONS.nicknames,
  },
  articleCSS: {
    title: TRANSLATIONS.articleCSS,
  },
  geofenceIds: {
    title: TRANSLATIONS.geofenceIds,
    validationType: 'number',
  },
  formalName: {
    title: TRANSLATIONS.formalName,
  },
  orgId: {
    title: TRANSLATIONS.orgId,
  },
  id: {
    title: TRANSLATIONS.publisherId,
  },
  businessProfileId: {
    title: TRANSLATIONS.businessProfileId,
  },
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ advancedAdsMinSlots: { title: Element; con... Remove this comment to see the full error message
export const INLINE_NUMBER_INPUT_DEFINITION: PublisherKeyToInlineNumberInput = {
  advancedAdsMinSlots: {
    title: TRANSLATIONS.advancedAdsMinSlots,
    content: TRANSLATIONS.advancedAdsMinSlotsContent,
  },
  advancedAdsMaxSlots: {
    title: TRANSLATIONS.advancedAdsMaxSlots,
    content: TRANSLATIONS.advancedAdsMaxSlotsContent,
  },
  advancedAdsMinSpacing: {
    title: TRANSLATIONS.advancedAdsMinSpacing,
    content: TRANSLATIONS.advancedAdsMinSpacingContent,
  },
  advancedAdsLatestFirstSlot: {
    title: TRANSLATIONS.advancedAdsLatestFirstSlot,
    content: TRANSLATIONS.advancedAdsLatestFirstSlotContent,
  },
  timeUntilAutoPublishFeedStory: {
    title: TRANSLATIONS.timeUntilAutoPublishFeedStory,
    content: TRANSLATIONS.timeUntilAutoPublishContent,
  },
  webviewLimit: {
    title: TRANSLATIONS.webviewLimit,
    content: TRANSLATIONS.webviewLimitContent,
  },
};

export const IMAGE_INPUT_DEFINITION: PublisherKeyToImageInput = {
  horizontalIconBlob: {
    title: TRANSLATIONS.articleFooterIcon,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ title: JSX.Element; uploadType: string; }'... Remove this comment to see the full error message
    uploadType: UploadPurpose.TILE_LOGO,
  },
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ contentAccessLists: { title: Element; }; }... Remove this comment to see the full error message
export const COUNTRY_INPUT_DEFINITION: PublisherKeyToCountryInput = {
  contentAccessLists: {
    title: TRANSLATIONS.distribution,
  },
};

// @ts-expect-error ts-migrate(2740) FIXME: Type '{ whitelistLanguage: { title: Element; butto... Remove this comment to see the full error message
export const MULTI_SELECT_INPUT_DEFINITION: PublisherKeyToMultiSelectInput = {
  allowlistLanguage: {
    title: TRANSLATIONS.language,
    buttons: LanguageList.reduce((acc, language) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[language.value] = language.label;
      return acc;
    }, {}),
  },
  localContent: {
    title: TRANSLATIONS.localContent,
    buttons: mapValues(keyBy(CountryList, 'value'), 'label'),
  },
};
